import {
  useLoaderData,
  useMatches,
  useFetcher,
  type Params,
} from "@remix-run/react";
import type { Resource, ServiceState } from "app/types/permissions";
import type { loader } from "app/routes/__vendor-portal";
import { DropdownSwitcher } from "~/components/DropdownSwitcher";
import { Inline, Text } from "~/components/vendorUI";
import { LoadingOverlay } from "~/components/Loader";
import { useTranslation } from "~/utils";
import { css } from "ui/css";

interface IMatches {
  id: string;
  pathname: string;
  params: Params<string>;
  data: unknown;
  handle: unknown;
}

type HandleType = {
  campaignType: "ppc" | "ppl";
};

export const CampaignSwitcher = () => {
  const { session } = useLoaderData<typeof loader>();
  const matches: IMatches[] = useMatches();

  if (!("userId" in session && session.isLoggedIn)) return <></>;

  const campaignMatches = matches.filter(
    (match) =>
      match.handle &&
      typeof match.handle === "object" &&
      Object.prototype.hasOwnProperty.call(match.handle, "campaignType"),
  );

  if (campaignMatches.length === 0) return <></>;

  const match = campaignMatches.at(-1);

  const type = (match?.handle as HandleType).campaignType;

  return type === "ppl" ? <PPLCampaignSwitcher /> : <PPCCampaignSwitcher />;
};

const PPCCampaignSwitcher = () => {
  const { session } = useLoaderData<{
    session: {
      permissions?: Record<"ppc", ServiceState>;
      selectedPPCCampaign: Resource;
    };
  }>();
  const fetcher = useFetcher({ key: "ppc-campaign-switcher" });
  const { t } = useTranslation();

  const isRevalidating = fetcher.state !== "idle";
  const TRANSLATION_KEY_MAP: Record<number, string> = {
    0: t("CAMPAIGN-SWITCHER_ACTIVE-CAMPAIGNS_LABEL"),
    1: t("CAMPAIGN-SWITCHER_PAUSED-CAMPAIGNS_LABEL"),
    2: t("CAMPAIGN-SWITCHER_DRAFT-CAMPAIGNS_LABEL"),
  };

  const items = (session.permissions?.ppc.resources || [])
    .filter((res) => res.program === "upgraded" || res.program === "draft")
    .map((item) => ({
      ...item,
      groupOrder: item.paused ? 1 : item.program === "draft" ? 2 : 0,
    }))
    .sort((i1, i2) => i1.groupOrder - i2.groupOrder)
    .map(({ groupOrder, ...item }) => ({
      ...item,
      groupName: TRANSLATION_KEY_MAP[groupOrder],
    }));

  const optimisticCampaign = items.find(
    ({ id }) => `${id}` === fetcher.formData?.get("id"),
  );

  return (
    <SwitcherWrapper>
      {isRevalidating ? <LoadingOverlay /> : null}
      {items.length > 0 ? (
        <Text color="brand" weight="medium" size="14px">
          {t("CAMPAIGN-SWITCHER_CAMPAIGN_LABEL")}
        </Text>
      ) : null}
      <DropdownSwitcher
        items={items}
        groupByKey={"groupName"}
        selectedItem={optimisticCampaign || session.selectedPPCCampaign}
        onSelect={({ id }) => {
          fetcher.submit(
            { intent: "setSelectedPPCCampaign", id },
            { method: "POST", action: "/?index" },
          );
        }}
        contentAlign="end"
        by="id"
      />
    </SwitcherWrapper>
  );
};

const PPLCampaignSwitcher = () => {
  const { session } = useLoaderData<{
    session: {
      permissions?: Record<"ppl", ServiceState>;
      selectedPPLCampaign: Resource;
    };
  }>();
  const { t } = useTranslation();
  const fetcher = useFetcher({ key: "ppl-campaign-switcher" });
  const isRevalidating = fetcher.state !== "idle";

  const items = (session.permissions?.ppl.resources || []).sort(
    (resource1, resource2) => resource1.name.localeCompare(resource2.name),
  );
  const optimisticCampaign = items.find(
    ({ id }) => `${id}` === fetcher.formData?.get("id"),
  );

  return (
    <SwitcherWrapper>
      {isRevalidating ? <LoadingOverlay /> : null}
      {items.length > 0 ? (
        <Text color="brand" weight="medium" size="14px">
          {t("CAMPAIGN-SWITCHER_CAMPAIGN_LABEL")}
        </Text>
      ) : null}
      <DropdownSwitcher
        items={items}
        selectedItem={optimisticCampaign || session.selectedPPLCampaign}
        onSelect={({ id }) => {
          fetcher.submit(
            { intent: "setSelectedPPLCampaign", id },
            { method: "POST", action: "/?index" },
          );
        }}
        contentAlign="end"
        by="id"
      />
    </SwitcherWrapper>
  );
};

/**
 * can be refactored once remix migtaion is completed,
 * it allows us to have 2 versions of campaign switcher in layout simultaneously.
 */
const campaignSwitcherWrapperCSS = css({
  marginBlockStart: "24px",
  marginInlineStart: "auto",
});

const SwitcherWrapper = ({ children }: { children: React.ReactNode }) => (
  <Inline
    align="center"
    distribute="end"
    gap="16px"
    className={campaignSwitcherWrapperCSS}
  >
    {children}
  </Inline>
);
